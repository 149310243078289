import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------
/*
const axiosInstance = axios.create({ baseURL: 'http://localhost:3001' });
const axiosInstance = axios.create({ baseURL: HOST_API});
*/
// const axiosInstance = axios.create({ baseURL: 'http://localhost:3001'});
const axiosInstance = axios.create({ baseURL: 'http://121.254.249.183:8080' });
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/auth/me',
    login: '/auth/login',
    register: '/auth/',
    list: '/auth/list',
    mailauth: '/auth/mailauth',
  },
  /*
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/',
    list: '/api/auth/list',
  },

*/

  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/auth/list',
    details: '/auth/details',
    search: '/auth/search',
  },
  notice: {
    list: '/notices/list',
    details: '/notices/details',
    search: '/notices/search',
    register: '/notices/',
    delete: '/notices/delete',
  },
  contentmgmt: {
    list: '/contents/list',
    details: '/contents/details',
    search: '/contents/search',
    register: '/contents/register',
    register2: '/contents/register2',
    fr: '/contents/fr',
    frre: '/contents/frre',
    delete: '/contents/delete',
  },
  vf: {
    list: '/vfs/list',
    details: '/vfs/details',
    search: '/vfs/search',
    register: '/vfs/register',
    delete: '/vfs/delete',
    test: '/vfs/test',
    test2: '/vfs/test2',
    upload: '/vfs/upload',
  },
  /*
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/product/search',
  },
*/
  user: {
    list: '/auth/list',
    details: '/auth/details',
    search: '/auth/search',
    register: '/auth/register',
    delete: '/auth/delete',
  },
};
