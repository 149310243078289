import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewNoticePage = lazy(() => import('src/pages/dashboard/notices'));

// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// MAKING PAGE

const MakingVideoViewPage = lazy(() => import('src/pages/dashboard/video/list'));
const MakingVideoCreatePage = lazy(() => import('src/pages/dashboard/video/new'));
const MakingVideoCreatePage2 = lazy(() => import('src/pages/dashboard/video/new2'));
const MakingVideoEditPage = lazy(() => import('src/pages/dashboard/video/edit'));

const MakingVideoStepPage1 = lazy(() => import('src/pages/dashboard/video/step1'));
const MakingVideoStepPage2 = lazy(() => import('src/pages/dashboard/video/step2'));

const MakingImageViewPage = lazy(() => import('src/pages/dashboard/image/list'));
const MakingImageCreatePage = lazy(() => import('src/pages/dashboard/image/new'));
// CONTENT
const ContentListPage = lazy(() => import('src/pages/dashboard/content/list'));
const ContentDetailsPage = lazy(() => import('src/pages/dashboard/content/details'));

// VF
const VfListPage = lazy(() => import('src/pages/dashboard/vf/list'));
const VfDetailsPage = lazy(() => import('src/pages/dashboard/vf/details'));
const VfCreatePage = lazy(() => import('src/pages/dashboard/vf/new'));
const VfEditPage = lazy(() => import('src/pages/dashboard/vf/edit'));

// NOTICE
const NoticeDetailsPage = lazy(() => import('src/pages/dashboard/notice/details'));
const NoticeListPage = lazy(() => import('src/pages/dashboard/notice/list'));
const NoticeCreatePage = lazy(() => import('src/pages/dashboard/notice/new'));
const NoticeEditPage = lazy(() => import('src/pages/dashboard/notice/edit'));

// CONTENTMGMT
const ContentMgmtDetailsPage = lazy(() => import('src/pages/dashboard/contentmgmt/details'));
const ContentMgmtListPage = lazy(() => import('src/pages/dashboard/contentmgmt/list'));
const ContentMgmtCreatePage = lazy(() => import('src/pages/dashboard/contentmgmt/new'));
const ContentMgmtEditPage = lazy(() => import('src/pages/dashboard/contentmgmt/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'notices', element: <OverviewNoticePage /> },

      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },

      {
        path: 'notice',
        children: [
          { element: <NoticeListPage />, index: true },
          { path: 'list', element: <NoticeListPage /> },
          { path: ':id', element: <NoticeDetailsPage /> },
          { path: 'new', element: <NoticeCreatePage /> },
          { path: ':id/edit', element: <NoticeEditPage /> },
        ],
      },
      {
        path: 'video',
        children: [
          { element: <MakingVideoViewPage />, index: true },
          { path: 'new', element: <MakingVideoCreatePage />, index: true },
          { path: 'new2', element: <MakingVideoCreatePage2 /> },
          { path: ':id/edit', element: <MakingVideoEditPage /> },
          { path: 'step1', element: <MakingVideoStepPage1 /> },
          { path: 'step2', element: <MakingVideoStepPage2 /> },
        ],
      },
      {
        path: 'image',
        children: [
          { element: <MakingImageCreatePage />, index: true },
          { path: 'new', element: <MakingImageCreatePage /> },
        ],
      },
      {
        path: 'content',
        children: [
          { element: <ContentListPage />, index: true },
          { path: 'list', element: <ContentListPage /> },
          { path: ':id', element: <ContentDetailsPage /> },
        ],
      },

      {
        path: 'vf',
        children: [
          { element: <VfListPage />, index: true },
          { path: 'list', element: <VfListPage /> },
          { path: ':id', element: <VfDetailsPage /> },
          { path: ':id/edit', element: <VfEditPage /> },
          { path: 'new', element: <VfCreatePage /> },
        ],
      },
      {
        path: 'contentmgmt',
        children: [
          { element: <ContentMgmtListPage />, index: true },
          { path: 'list', element: <ContentMgmtListPage /> },
          { path: ':id', element: <ContentMgmtDetailsPage /> },
          { path: ':id/edit', element: <ContentMgmtEditPage /> },
          { path: 'new', element: <ContentMgmtCreatePage /> },
        ],
      },
    ],
  },
];
