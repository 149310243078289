// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  terms: '/terms-us',
  contact: '/contact-us',
  policy: '/policy',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: '',
  changelog: '',
  zoneUI: '',
  minimalUI: '',
  freeUI: '',
  figma:'',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  notice: {
    root: `/notice`,
    checkout: `/notice/checkout`,
    details: (id) => `/notice/${id}`,
    demo: {
      details: `/notice/${MOCK_ID}`,
    },
  },
  contentmgmt: {
    root: `/content`,
    checkout: `/content/checkout`,
    details: (id) => `/content/${id}`,
    demo: {
      details: `/content/${MOCK_ID}`,
    },
  },
  vf: {
    root: `/vf`,
    checkout: `/vf/checkout`,
    details: (id) => `/vf/${id}`,
    demo: {
      details: `/vf/${MOCK_ID}`,
    },
  },


  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
   
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },

  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,

    general: {
      notice: `${ROOTS.DASHBOARD}/notices`,
    },
    
    management:{
      making: `${ROOTS.DASHBOARD}/making`,
      new: `${ROOTS.DASHBOARD}/making/new`,
    },

    video :{
      making: `${ROOTS.DASHBOARD}/video`,
      new: `${ROOTS.DASHBOARD}/video/new`,
      new2: `${ROOTS.DASHBOARD}/video/new2`,
      step1: `${ROOTS.DASHBOARD}/video/step1`,
      step2: `${ROOTS.DASHBOARD}/video/step2`,
      edit: (id) => `${ROOTS.DASHBOARD}/video/${id}/edit/`,

      image:{
        making: `${ROOTS.DASHBOARD}/image`,
        new: `${ROOTS.DASHBOARD}/image/new`,
      },
      content:{
        list: `${ROOTS.DASHBOARD}/content/list`,
        details: (id) => `${ROOTS.DASHBOARD}/content/${id}`,
       
      }
    },

    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
   
    notice: {
      root: `${ROOTS.DASHBOARD}/notice`,
      new: `${ROOTS.DASHBOARD}/notice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/notice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/notice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/notice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/notice/${MOCK_ID}/edit`,
      },
    },
    contentmgmt: {
      root: `${ROOTS.DASHBOARD}/contentmgmt`,
      new: `${ROOTS.DASHBOARD}/contentmgmt/new`,
      details: (id) => `${ROOTS.DASHBOARD}/contentmgmt/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/contentmgmt/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/contentmgmt/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/contentmgmt/${MOCK_ID}/edit`,
      },
    },
   
    vf: {
      root: `${ROOTS.DASHBOARD}/vf`,
      new: `${ROOTS.DASHBOARD}/vf/new`,
      details: (id) => `${ROOTS.DASHBOARD}/vf/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/vf/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/vf/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/vf/${MOCK_ID}/edit`,
      },
    },
  },
};
