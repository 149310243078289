// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const LINKS = [

  {
    headline: 'Legal',
    children: [
      { name: '이용약관', href: paths.terms },
      { name: '개인정보 처리방침', href: paths.policy },
    ],
  },
 
];

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          © All rights reserved
          <br /> made by
          <Link href="https://minimals.cc/"> minimals.cc </Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 10,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
       

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          
          <Grid xs={12} md={8}>
          <Logo sx={{ mb: 3 }} />
            <Typography
              variant="body2"
              sx={{
               
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              SR UNIVERSE<br/>
대표: 배지우 │ 주소: 202, 50, Donggyo-ro 22-gil, Mapo-gu, Seoul │ 이메일: sruniverse@sruniverse.kr<br/>
Copyright ⓒ 2023 SR UNIVERSE All Right Reserved
            </Typography>

            
          </Grid>

          <Grid xs={12} md={2}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >

                  <Typography component="div" variant="overline">
                 
                  <Link
                      key={list.children[0].name}
                      component={RouterLink}
                      href={list.children[0].href}
                      color="inherit"
                      variant="body2"
                      fontWeight="fontWeightBold"
                      paddingRight={1}
                    >
                      {list.children[0].name}
                    </Link>
              |
                    <Link
                      key={list.children[1].name}
                      component={RouterLink}
                      href={list.children[1].href}
                      color="inherit"
                      variant="body2"
                      fontWeight="fontWeightBold"
                      paddingLeft={1}
                    >
                     {list.children[1].name}
                    </Link>


 </Typography>

                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );

  return mainFooter;
}
